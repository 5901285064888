import React from 'react';
import Plx from 'react-plx';

import ThreeContainer from '../components/threeContainer'

//Assets
const gradientOne = [[57,1,100], [255,238,0], [0,159,255], [229,145,0]]
const gradientTwo = [[236,46,75], [0,93,39], [57,1,100], [246,255,0]]
const wrapperBackgroundClass = ['Purple', 'Green', 'Blue', 'Yellow']

const changeHeaderCenterParallaxData = [
  {
    start: '.ChangeBlock',
    duration: '45vh',
    easing: 'easeInOut',
    properties: [
      {
        startValue: -25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      }
    ],
  }
]

class ChangeBlock extends React.Component {
  
  constructor(props) {
    super(props)
  
    //Video Reference
    this.state = {
      threeColorOne: [57,1,100],
      threeColorTwo: [236,47,75],
      wrapperBackground: 'Purple'
     }
  }

  componentDidMount() {
    clearInterval(this.state.intervalID)
    var ivID = setInterval(this.colorRandomizer.bind(this),5000)
    this.setState({intervalID : ivID})
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID)
  }

  colorRandomizer() {
      console.log("Randomize Color")
      let pos = Math.floor(Math.random()*4)
      this.setState({threeColorOne:gradientOne[pos]})
      this.setState({threeColorTwo:gradientTwo[pos]})
      this.setState({wrapperBackground:wrapperBackgroundClass[pos]})
  }

  render() {
      return (     
        <>
        {/*Change Block*/}
        <div className={`ContentBlock ChangeBlock ${this.state.wrapperBackground}`}>
            <ThreeContainer visualType={this.props.visualType} colorOne={this.state.threeColorOne} colorTwo={this.state.threeColorTwo} ></ThreeContainer>
            <Plx parallaxData={ changeHeaderCenterParallaxData }>
              <h1 className="HeaderCenter">{this.props.children}</h1>
            </Plx>
        </div>
        </>
    )
  }
};

export default ChangeBlock;