import React from 'react'
import Plx from 'react-plx';
import VizSensor from 'react-visibility-sensor';
import SEO from '../components/seo'


import ChangeBlockVariable from '../components/changeBlockVariable'
import MouseButton from '../components/mouseButton'
import { Helmet } from "react-helmet"

//Video
import CEPVideo from "../videos/Sequence_CEP_1080.mp4"
import CEPVideo960 from "../videos/Sequence_CEP_960.mp4"

const sliderParallaxData = [
  {
    start: '.SecondBlock',
    startOffset:'99vh',
    duration: '1vh',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        unit: 'vh',
        property: 'height'
      }
    ]
  }
]


const masonaryHeaderRightParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: -5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]


const masonaryHeaderParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const verticalLineMasonaryParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '70vh',    
    properties: [
      {
        startValue: 0,
        endValue: 50,
        unit: 'vh',
        property: 'height',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]



export default class ParallaxPage extends React.Component {
  constructor(props) {
    super(props)
     //Video
    this.BannerVideoRef = React.createRef()
  }  
  componentDidMount() {
  }

  render() {
    return (
    <>
        <SEO title="Services" description="Brand advisory, Strategy ,Branding and design ,Brand positioning ,Brand design ,Core messaging ,Name generation ,Marketing strategy and planning ,Go-to-market activation ,Creative direction ,Content strategy and planning ,Sales enablement ,Social Media strategy ,Channel management ,MarTech integration ,Digital production ,UX design ,Website build ,Video / Motion graphics ,App / Digital platforms ,Advertising concepts ,Integrated campaigns ,Analytics"/>
        <Helmet>
          <title>Services</title>
        </Helmet>     
        {/*Slider Block*/}
        <div className="ContentBlock Centered SliderBlock">
        <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.BannerVideoRef.current.play() : this.BannerVideoRef.current.pause()
              }}
            >           
        <Plx parallaxData={ sliderParallaxData } className="SliderBlockWrapper">
          <div className="TopBanner">
                    <div>
                        <video ref={this.BannerVideoRef} autoPlay loop muted playsInline preload="metadata">
                          <source src={CEPVideo}/>                          
                          <source src={CEPVideo960} media="all and (max-width: 960px)"/>                                                     
                          </video>
                        <div className="ImageOverlay"></div>
                    </div>
          </div>
          </Plx>
          </VizSensor>
          <MouseButton></MouseButton>

        </div>


        {/*CEP Block */}              
        <div className="ContentBlock Centered SecondBlock" id="SecondBlock">
          <div className="HeaderWrapper Centered">
            <h1 className="WhiteHighlight">Services</h1>
          </div>

          <div className="ColumnCount3 MarginTop5">
                <ul className="no-bullet bold">
                <li>Brand advisory</li>
                <li>Strategy</li>
                <li>Branding &amp; design
                    <ul>
                    <li>Brand positioning</li>
                    <li>Brand design</li>
                    <li>Core messaging</li>
                    <li>Name generation</li>
                    </ul>
                </li>    
                <li className="break-before">Marketing strategy &amp; planning</li>
                <li>Go-to-market activation</li>
                <li>Creative direction</li>
                <li>Content strategy &amp; planning</li>
                <li>Sales enablement</li>
                <li className="mobile-break-before">Social Media strategy</li>
                <li>Channel management</li>
                <li>MarTech integration</li>
                <li className="break-before">Digital production
                    <ul>
                    <li>UX design</li>
                    <li>Website build</li>
                    <li>Video / Motion graphics</li>
                    <li>App / Digital platforms</li>
                    </ul>
                </li>
                <li>Advertising concepts</li>
                <li>Integrated campaigns</li>
                <li>Analytics</li>
                </ul>
                    </div>
        </div>

      {/*Change Block*/}
      <ChangeBlockVariable visualType={'wave'}>
        The right approach<br/>is critical to winning.
      </ChangeBlockVariable>

      {/*Activate brand*/}
      <div className="ContentBlock Centered MasonaryBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1 className="TwoLevel"><strong>Integration</strong> is oxygen for growth</h1>
          </Plx>

          <div className="MasonaryContent">
            <h2> Integrate people, functions, channels and platforms to scale experience and reach.</h2>
          </div>
        </div>

      {/*Harmonize message and value*/}
      <div className="ContentBlock Centered MasonaryBlock PurpleBlock RightBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
            <h1 className="TwoLevel"><strong>Simultaneity</strong> incites creativity</h1>
          </Plx>

          <div className="MasonaryContent">
            <h2>Do many things at the same time to understand impact and discover strengths.</h2>
            </div>

          
        </div>

        {/*Manifest an evolved customer experience*/}
      <div className="ContentBlock Centered MasonaryBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1 className="TwoLevel">midstream <strong>adaptation</strong> is normal</h1>
          </Plx>

          <div className="MasonaryContent">
            <h2>Since growth requires change, adaptation is a permanent element of process.</h2>
          </div>

         
        </div>

        {/*Galvanize sales opportunities around content and leadership*/}
        <div className="ContentBlock Centered MasonaryBlock RedBlock RightBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
          <h1 className="TwoLevel">indecision suffocates <strong>execution</strong></h1>
          </Plx>

          <div className="MasonaryContent">
                      <h2>Indecision slows momentum and produces less cohesive work.</h2>
          </div>

          
        </div>

        {/*Reach clients and stakeholders with sophistication, strength and consistency*/}
        <div className="ContentBlock Centered MasonaryBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1 className="TwoLevel">
         diffuse <strong>focus</strong> corrupts results
          </h1>
          </Plx>

          <div className="MasonaryContent">
            <h2>You can’t speak clearly to everyone at the same time, and you can’t measure success without an alignment of focus and goals.</h2>
          </div>

          
        </div>

              {/*Harmonize message and value*/}
      <div className="ContentBlock Centered MasonaryBlock PurpleBlock RightBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderRightParallaxData}>
            <h1 className="TwoLevel">
              <strong>prioritization</strong> drives efficiency
            </h1>
          </Plx>

          <div className="MasonaryContent">
            <h2>There is no limit to how much can be done; plan where to play and you will maximize value.</h2>
            </div>

        </div>

        {/*Manifest an evolved customer experience*/}
      <div className="ContentBlock Centered MasonaryBlock">
          <div className="VerticalLineContainer">
          <Plx parallaxData={verticalLineMasonaryParallaxData}>
              <div className="VerticalLine Short"></div>
          </Plx> 
          </div>

          <Plx parallaxData={masonaryHeaderParallaxData}>
          <h1 className="TwoLevel">
            <strong>intelligence</strong> breeds value
          </h1>
          </Plx>

          <div className="MasonaryContent">
            <h2>Start with quality information and make a patient and thoughtful assessment of the future. </h2>
          </div>

        </div>

        </>
      )
    }
}